import React, { ChangeEvent, useEffect, useState } from "react";
import {
    Box,
    Center,
    Container,
    HStack,
    Spacer,
    Image,
    Text,
    Stack,
    Button,
    ButtonGroup,
    IconButton,
    Divider,
    Select,
    Flex,
    Code,
    background,
    Icon,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Circle,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Input,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react";
import ShowToast from "../components/ShowToast";
import GaugeComponent from 'react-gauge-component'
import ForgingMeter from "../components/ForgingMeter";
import { FaUser } from "react-icons/fa";
import { IoHome } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { AddIcon, BellIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { FaUserCircle } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import logo from "../assets/images/logo.png"
import AnalogMeter from "../components/AnalogMeter";
import { useAppDispatch, useAppSelector } from "../Redux/hooks";
import * as userService from "../services/userServices";
import Loader from "../components/loader";
import { onMessageListener, requestForToken } from '../firebaseNotifications/firebase';
import { getUserInfo } from "../Redux/userSlice";
import { getUserDeviceDataByDeviceId, getUserDeviceDataByDeviceIdAndDate } from "../Redux/deviceSlice";
import Logout from "../components/Logout";
import UserProfile from "./UserProfile";

const Forging: React.FC = () => {
    const dispatch = useAppDispatch();
    const [dateTime, setDateTime] = useState<string>('')
    const { dataUser, isLoading } = useAppSelector((state: any) => state.user)
    const { dataDevice, invalidDeviceError } = useAppSelector((state: any) => state.device)
    const [selectedDevice, setSelectedDevice] = useState("")
    const [selectedDate, setSelectedDate] = useState('');
    const [showCurrentDate, setShowCurrentDate] = useState('')
    const [toastErrorMessage, setToastErrorMessage] = useState<string>('')
    const [toastIsError, setToastIsError] = useState<boolean>(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [meterReading, setMeterReading] = useState(0)
    const [showProfile, setShowProfile] = useState(false)

    const toggleShowProfile = () => {
        setShowProfile(true)
    }

    useEffect(() => {
        dispatch(getUserInfo())
    }, [dispatch])

    // Set the default date to current date when component mounts
    useEffect(() => {
        const currentDate = new Date()?.toISOString()?.split('T')[0]; // YYYY-MM-DD format
        setSelectedDate(currentDate);
    }, []); // Empty dependency array ensures this runs only once

    // SETTING FIRST DEVICE AS DEFAULT SELECTED AND HIT API FOR FETCHING DATA OF SELECTED DEVICE:
    useEffect(() => {
        setSelectedDevice(dataUser?.devices?.[0]?.deviceId)
        setShowLoader(true)
        dispatch(getUserDeviceDataByDeviceId({ deviceId: dataUser?.devices?.[0]?.deviceId }));
        setShowLoader(false)
    }, [dataUser?.devices?.[0]?.deviceId])


    // FOR SETTING THE METER READING 
    useEffect(() => {
        if (dataDevice && dataDevice[dataDevice.length - 1]?.currentTemp) {
            setMeterReading(dataDevice[dataDevice.length - 1]?.currentTemp)
        }
    }, [dataDevice])


    // SEND THE API REQUEST HERE TO SHOW
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedDevice(e?.target?.value);
        if (e?.target?.value) {
            dispatch(getUserDeviceDataByDeviceId({ deviceId: e?.target?.value }));
            if (invalidDeviceError && invalidDeviceError?.message) {
                setToastIsError(true);
                setToastErrorMessage(invalidDeviceError?.message);
            }
        } else {
            setToastIsError(true);
            setToastErrorMessage("Please select a device");
        }
    };

    // HANDLE DATE PICKER FOR SHOWING DEVICE DATA ACCORDING TO DATE:
    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedDate(e.target.value);
    };

    const showDateBasedDeviceData = () => {

        // API CALL FOR FETCHING DEVICE READING ON THE BASIS OF DATE:
        if (!selectedDevice) {
            setToastIsError(true);
            setToastErrorMessage("Please select a device");
        } else if (selectedDate && selectedDevice) {
            dispatch(getUserDeviceDataByDeviceIdAndDate({
                deviceId: selectedDevice,
                date: selectedDate
            }))
            setShowCurrentDate(yearMonthTime(selectedDate))
            if (invalidDeviceError && invalidDeviceError?.message) {
                setToastIsError(true);
                setToastErrorMessage(invalidDeviceError?.message);
            }
        }
        else {
            // SELECET DATE Error MESSAGE
            setToastIsError(true);
            setToastErrorMessage("Please select Valid date");
        }
    }

    const handleSubscription = async () => {
        if (typeof Notification !== 'undefined') {
            try {
                const hasPermission = await Notification.requestPermission();
                if (hasPermission === 'granted') {
                    const firebaseToken = await requestForToken();
                    if (firebaseToken) {
                        const subscribed = await userService.userSubscription(
                            firebaseToken
                        );
                        if (subscribed) {
                            <ShowToast message="you are now" resStatus="success" />
                            // showToast('you are now', 'success');
                        }
                    }
                }
            } catch (error: any) {
                if (error.response?.data?.message === 'duplicate id') return;
                <ShowToast message={error.message} resStatus="error" />
            }
            onMessageListener();
        } else {
            console.log('push notifications not supported');
            alert("not working because it's still not supported");
        }
    };


    // BASED ON CUSTOMERTYPE AND NOTIFCATION VALUE HANDLE PUSH NOTIFICATION
    const customerType = localStorage.getItem('customerType')
    const isNotify = localStorage.getItem('notification')
    useEffect(() => {
        customerType === "forging" && isNotify === "true" && handleSubscription();
    }, [])

    const handleRequestNotification = async () => {
        const response = await userService.requestNotification()
    }

    // SETTING YEAR MONTH DAY ON LOAD TO TODAYS:
    useEffect(() => {
        setShowCurrentDate(yearMonthTime())
    }, [])

    const yearMonthTime = (value?: any) => {
        if (value) {
            const currentDate = new Date(value);
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const monthIndex = currentDate.getMonth();
            const month = months[monthIndex];
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();
            const formattedDate = `${day} ${month} ${year} `;
            return formattedDate
        } else {
            const currentDate = new Date();
            const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            const monthIndex = currentDate.getMonth();
            const month = months[monthIndex];
            const day = currentDate.getDate();
            const year = currentDate.getFullYear();
            const formattedDate = `${day} ${month} ${year} `;
            return formattedDate
        }
    }

    const timeFormat = (value: any) => {
        const dateObject = new Date(value);
        let hours = dateObject.getHours();
        const amPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
        const minutes = dateObject.getMinutes();
        const seconds = dateObject.getSeconds();
        const timeString = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, "0")} ${amPm} `;
        return timeString
    }

    // HITTING API FOR REAL TIME DATA AFTER 15 MINUTES FOR DEVICE READING:
    useEffect(() => {
        const interval = setInterval(() => {
            // for refresh after half an hour or  15 min
            dispatch(getUserDeviceDataByDeviceId({ deviceId: selectedDevice }));
        }, 15 * 60 * 1000);
        return () => clearInterval(interval);
    }, [])

    // ON CLICKING REFRESH DATA HITTING API AND SHOW ERROR MESSAGE IF EXISTS
    const refreshData = (data: string) => {
        if (!data) {
            setToastIsError(true);
            setToastErrorMessage("Please select a device");
        } else {
            dispatch(getUserDeviceDataByDeviceId({ deviceId: selectedDevice }))
        }
    }

    const firstTwoLettersOfName = (name: string) => {
        const s1 = name[0]?.toUpperCase();
        const spaceIndex = name.indexOf(' ');
        if(spaceIndex > 0){
            const s2 = name[spaceIndex + 1]?.toUpperCase();
            return `${s1}${s2}`
        }else{
            return `${s1}`
        }
    }

    return (
        <>
            {toastIsError && <ShowToast message={toastErrorMessage} resStatus="error" setFunction={setToastIsError} />}
            {isLoading ? <Loader /> : (
                <Flex
                    flexDirection={"row"}
                    alignItems={"center"}
                    backgroundColor={"blue"}

                >
                    <Flex
                        flexDirection={"column"}
                        alignItems={"center"}
                        width='100%'
                        backgroundColor={"#fff"}
                    >
                        <Flex
                            flexDirection={["column", "row"]}
                            alignItems={"center"}
                            // height={"90px"}
                            justifyContent="space-between"
                            width={"100%"}
                            borderBottom={"1px solid #DADADA"}
                            boxShadow={"3px 6px 8px 0px #DADADA"}
                            bgColor={"#fff"}
                        >

                            <Flex alignItems={"center"} flexDirection={"row"} width={"100%"}>

                                <Flex
                                    alignItems={"center"}
                                    flexDirection={"column"}
                                >
                                    <Box mt={2} ml={5}>
                                        <Image width={["96%", "300px"]} src={logo} alt="Logo" />
                                    </Box>
                                </Flex>
                                <Spacer />
                                <Flex
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                >
                                    <Menu >
                                        <MenuButton>
                                            {/* <Code

                                                mr={["0px", "10px"]}
                                                mt={["4px", "2px"]}> */}
                                                {/* <FaUserCircle size={"35"} /> */}
                                                <Circle 
                                                    size={["35px", '40px']} 
                                                    bg='#3b5998' 
                                                    color='white' 
                                                    mr={["5px", "10px"]}
                                                    mt={["4px", "2px"]}
                                                    fontWeight={"bold"}
                                                >
                                                    {firstTwoLettersOfName(dataUser?.username)}
                                                </Circle>


                                            {/* </Code> */}
                                        </MenuButton>
                                        <MenuList bgColor={"#3b5998"}>
                                            <MenuItem
                                                fontSize={"sm"}
                                                fontWeight={"bold"}
                                                color={"#fff"}
                                                bgColor={"#3b5998"}
                                                _hover={{
                                                    cursor: "default"
                                                }}
                                            >
                                                Welcome {`${dataUser?.username.charAt(0).toUpperCase()}${dataUser?.username.slice(1)}`}
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem
                                                fontSize={"sm"}
                                                fontWeight={"bold"}
                                                color={"#fff"}
                                                bgColor={"#3b5998"}
                                                _hover={{
                                                    bgColor: "#72A0C1",
                                                    opacity: 0.9,
                                                }}
                                                onClick={() => toggleShowProfile()}
                                            >
                                                Profile
                                            </MenuItem>
                                            <Divider />
                                            <Logout type="forging" />
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex
                            flexDirection={["column", "row"]}
                            alignItems={"center"}
                            height={"100%"}
                            width={"100%"}
                            bgColor={"#fff"}
                            mt={"20px"}
                        >

                            {/* { user profile component comes here} */}
                            {showProfile ? <UserProfile setShowProfile={setShowProfile} showProfile={showProfile} /> :
                                <Flex
                                    flexDirection={"column"}
                                    alignItems={"center"}
                                    width='100%'
                                    h={'100%'}
                                    backgroundColor={"gray.100"}
                                >

                                    <Flex
                                        flexDirection={"row"}
                                        alignItems={"left"}
                                        width={"100%"}
                                        bgColor={"#fff"}
                                        overflow={"hidden"}
                                    >

                                        <Container bg='#fff'>
                                            <Flex
                                                flexDirection={"column"}
                                                alignItems={"center"}
                                            >

                                                <Flex flexDirection={"column"} width={"100%"} mb={"3px"}>
                                                    <Center>
                                                        <Text fontSize={'23px'} fontWeight={"bold"}>Dashboard</Text>
                                                        <IconButton
                                                            ml={"10px"}
                                                            variant='outline'
                                                            colorScheme="facebook"
                                                            size={"sm"}
                                                            aria-label='last refreshed'
                                                            icon={<HiRefresh />}
                                                            // onClick={() => dispatch(getUserDeviceDataByDeviceId({ deviceId: selectedDevice }))}
                                                            onClick={() => refreshData(selectedDevice)}
                                                        />
                                                    </Center>
                                                </Flex>

                                                <Flex flexDirection={["column", "row"]} alignItems={"center"} >
                                                    <Flex flexDirection={"column"} mr={"5px"}>
                                                        <Center>
                                                            <Select
                                                                width={["235px"]}
                                                                name={"deviceSelected"}
                                                                value={selectedDevice}
                                                                placeholder='Select Device'
                                                                color={"gray"}
                                                                onChange={(e) => handleChange(e)}
                                                                border={"1px solid blue"}>
                                                                {
                                                                    dataUser?.devices?.map((value: any, index: number) => {
                                                                        return (
                                                                            <option key={index} value={value?.deviceId} >{value.deviceName}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </Center>
                                                    </Flex>

                                                    <Flex flexDirection={"column"}>
                                                        <Center >
                                                            <Input
                                                                size={"md"}
                                                                color={"gray"}
                                                                mt={["10px", "0px"]}

                                                                borderRadius={"none"}
                                                                borderLeftRadius={"6px"}
                                                                type="date"
                                                                name="datetime-local"
                                                                border={"1px solid blue"}
                                                                borderRight={"none"}
                                                                value={selectedDate}
                                                                onChange={(e) => handleDateChange(e)}
                                                            />

                                                            <Button
                                                                size={"md"}
                                                                colorScheme="facebook"
                                                                mt={["10px", "0px"]}
                                                                borderRadius={"none"}
                                                                borderRightRadius={"6px"}
                                                                // border={"1px solid blue"}
                                                                borderLeft={"none"}
                                                                fontSize={"sm"}
                                                                onClick={() => showDateBasedDeviceData()}
                                                            >Show</Button>
                                                        </Center>
                                                    </Flex>

                                                </Flex>

                                                <Flex
                                                    mt={"15px"}
                                                    mb={"10px"}
                                                    flexDirection={"column"}
                                                    alignItems={"center"}
                                                    width={"100%"}
                                                    bgColor={"#2d2f30"}
                                                    border={"15px solid black"}
                                                >
                                                    <ForgingMeter minRange={300} maxRange={1500} currentValue={meterReading} />
                                                </Flex>
                                            </Flex>
                                        </Container>
                                    </Flex>


                                    {/* {TABLE FLEX} */}
                                    <Flex
                                        flexDirection={"row"}
                                        alignItems={"center"}
                                        width={"100%"}
                                    >
                                        {
                                            invalidDeviceError ? <Container maxW='container.xlg'>
                                                <Box backgroundColor={"facebook.800"} color={"#fff"} textAlign={"center"}>
                                                    <Text p={"20px"} fontWeight={"bold"} letterSpacing={"1px"}>No data found for current device.</Text>
                                                </Box>
                                            </Container> :
                                                !selectedDevice ?
                                                    <Container maxW='container.xlg'>
                                                        <Box backgroundColor={"facebook.800"} color={"#fff"} textAlign={"center"}>
                                                            <Text p={"20px"} fontWeight={"bold"} letterSpacing={"1px"}>No data found for current device.</Text>
                                                        </Box>
                                                    </Container> :
                                                    <TableContainer backgroundColor="#fff" width="100%">
                                                        <Table variant="simple" width="100%" >
                                                            <Thead>
                                                                <Tr bgColor={"facebook.800"}>
                                                                    <Th color={"#fff"} textAlign={"center"}>Min Temp</Th>
                                                                    <Th color={"#fff"} textAlign={"center"}>Max Temp</Th>
                                                                    <Th color={"#fff"} textAlign={"center"}>Live Temp</Th>
                                                                    <Th color={"#fff"} textAlign={"center"}>Time</Th>
                                                                </Tr>
                                                                {
                                                                    showLoader &&
                                                                    <Tr>
                                                                        <Td colSpan={4} textAlign={"center"}>
                                                                            <Loader />
                                                                        </Td>
                                                                    </Tr>
                                                                }
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    dataDevice && dataDevice.map((value: any, index: number) => {
                                                                        return (
                                                                          <Tr>
                                                                            <Td
                                                                              textAlign={
                                                                                'center'
                                                                              }
                                                                            >
                                                                              {
                                                                                value.minTemp
                                                                              }
                                                                            </Td>
                                                                            <Td
                                                                              textAlign={
                                                                                'center'
                                                                              }
                                                                            >
                                                                              {
                                                                                value.maxTemp
                                                                              }
                                                                            </Td>
                                                                            <Td
                                                                              textAlign={
                                                                                'center'
                                                                              }
                                                                            >
                                                                              {
                                                                                value.currentTemp
                                                                              }
                                                                            </Td>
                                                                            <Td
                                                                              textAlign={
                                                                                'center'
                                                                              }
                                                                            >
                                                                              {timeFormat(
                                                                                value.updatedAt
                                                                              )}
                                                                            </Td>
                                                                          </Tr>
                                                                        );
                                                                    })
                                                                }
                                                                <Tr>
                                                                    <Td colSpan={4}></Td>
                                                                </Tr>
                                                            </Tbody>
                                                        </Table>
                                                    </TableContainer>
                                        }
                                    </Flex>
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                </Flex >
            )}
        </>
    )
}

export default Forging

